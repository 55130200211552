import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import AmberScore from "../components/reusable/AmberScore";

const UnifiedSow = () => (
    <Layout>
        <SEOComponent title="Amber by inFeedo | Privacy Policy" />
        <div class="content has-text-centered columns flex-wrap mar-top-160">
            <h4 class="text-block-17 column is-12 is-paddingless"><u>Scope, Product Specifications, Features and other details pertaining to the <br></br>Software and Service Levels</u></h4><br></br> <br></br>
            <h4 class="text-block-17 column is-12 is-paddingless mg-top-20"><u>Part A: Product Specifications and Features</u></h4>
            <div className="font-15 pd-40 column is-9 mar-auto has-text-justified">
                <h6 className="has-text-weight-bold"><u>NOTE: PRODUCT SPECIFICATIONS MAY OR MAY NOT APPLY TO A CUSTOMER DEPENDING ON THE MODULES THEY HAVE PURCHASED. </u></h6>
                <h6 className="has-text-weight-bold">The scope of work and product features of the Company’s software, “Amber” and provision of its SaaS Services can be split into 2 main parts :
                    <ol type="A">
                        <li>Amber Chat</li>
                        <li>Amber Dashboard</li>
                    </ol>
                </h6>

                <ol type="A">
                    <li><b>Scope of work and features of Amber Chat</b>
                        <ol type="1">
                            <li>Company shall provide a non-exclusive, non-transferable license, to use the AI Chat-Bot called <b>‘Amber’</b> on a SAAS (Software as service) model which will be on a per-employee basis;. </li>
                            <li>The Customer understands and acknowledges that Amber shall be interacting/communicating with its employees as per the instructions set up on the platform, in the form of a textual chat.
                                <ol type="1">
                                    <li>These are multiple points in an employee’s journey in an organization when the Software will interact with them over. A noncomprehensive list of reach-out patterns is:
                                        <ol type="1">
                                            <li>When an employee completes a certain tenure in the organization. Example 2 years</li>
                                            <li>When an employee has a significant change in their work. Example Manager Change </li>
                                            <li>When the employee resigns or leaves the organization</li>
                                            <li>When certain data is needed by the management team and an ad-hoc questionnaire is sent. </li>
                                            <li>When the employee has a query or needs the AI Assist.</li>
                                            <li>When the employee wants to message the Customer anonymously</li>
                                        </ol>
                                    </li>
                                    <li>Certain touchpoints will have a compulsory question called the “Anchor Question”. This question is common throughout all stages and is not customizable. The Anchor Question helps the Software do a mood analysis across the entire organization and share it in the dashboard.</li>
                                </ol>
                            </li>
                            <li>Based on the above interactions the other module “Amber Dashboard” will generate surface reports introduced to employees based on demographics like Business Units, Location, Circle, Band, Level, Age on Network, etc. at different points of time. The tool in the Software has the ability to create multiple instances (cohorts) with a customized question based on any one of the demographics. However, this is dependent on the customer providing the required demographic data. </li>
                            <li>Company may also share best practices and may also consult with the Customer in order to design the questions.</li>
                            <li>The Company on a best effort basis shall ensure that the Software’s up time will be 99.95%. The Company shall also provide 24*5 tech support globally through its technical help desk – Which can be reached via email at <a href="mailto:help@infeedo.com">help@infeedo.com</a>.</li>
                            <li>The Company on a best effort basis shall ensure that the Amber Chat link is sent on the channels that are used by the customer. Example Slack, MS Teams, etc</li>
                        </ol>
                    </li>
                    <br />
                    <li><b>Scope of work and features of Amber Dashboard</b>
                        <ol type="1">
                            <li><b>Dashboard:</b> Amber’s analytics dashboard has 6 mini-features built into it:
                                <ol type="a">
                                    <li><u>People to Meet:</u> People who are detected as unhappy by our algorithm and NLP are shown in this section. Idea is to prioritize meeting those people over the others to resolve issues and avoid termination and/or resignation due to such issues in the Customer’s organization.</li>
                                    <li><u>Anonymous Messages:</u> All messages sent through Anonymous Messages ( on Amber Chatbot) are shown in this section of the Dashboard.</li>
                                    <li><u>Analytics:</u> This section shows the analysis of different metrics measured throughout the employee lifecycle</li>
                                    <li><u>Themes:</u> Find out common trends across each department, location, function, age group etc.</li>
                                    <li><u>Filters:</u> Filters provided in Software allows admins to slice and dice the dashboard in various ways allowing them the flexibility to choose what to see.</li>
                                    <li><u>Engagement Score:</u> This is a summarized score of complete chat and provides a holistic view of the sentiment of complete cha</li>
                                    <li><u>TextSens:</u> A proprietary text analytics module developed by our AI team to help you analyze incoming responses from chats.</li>
                                    <li><u>Saved views:</u> Admins can create and save views on the dashboard. They can also choose which view should be their default view every time they log in to the dashboard</li>
                                    <li><u>Themes:</u> Find out common trends across each department, location, function, age group etc. Analytics to find out under which demography which theme is not working well.  </li>
                                    <li><u>Configurable Reports:</u> Customers can generate reports based on the demographics and filters required by them. </li>
                                    <li><u>Pulse:</u>  Customers can launch and analyze customized pulse surveys</li>
                                    <li><u>Crisp Chats:</u> Customers can read chat transcripts of their employee's basis the right permissions have been provided to the administrator accessing the dashboard.</li>
                                </ol>
                            </li>
                            <br />
                            <li><b>Settings and Configuration: </b>
                                <ol type="a">
                                    <li><b>Configure Amber’s Reachout Templates and Channels:</b>
                                        <ol type="i">
                                            <li>Amber sends the following 2 reachouts to employees:
                                                <ol type="1">
                                                    <li><u>Reachouts:</u> The reachout email is the one in which users are asked to share their 	feedback and this can be accessed by clicking on Amber’s chat URL link which will be 	provided in the said email. This email’s content is entirely customisable. It usually goes in the name of the Customer’s CEO (otherwise CHRO/HR Head).</li>
                                                    <li><u>Reminders:</u> To ensure maximum participation, 4 auto-reminders are sent in a span of 30 days.</li>
                                                </ol>
                                            </li>
                                            <li>These reachouts will go on predetermined channels, such as Slack, Email, MS Teams etc basis the integration done with the customer.</li>
                                        </ol>
                                    </li>
                                    <br />

                                    <li><b>Amber’s Reachouts to Admins:</b>
                                        <ol type="i">
                                            <li><u>Daily Report:</u> An email also goes out daily with the list of employees who chatted with Amber the day before. Admins can choose to unsubscribe from these emails too.</li>
                                            <li><u>Schedule 1:1/Follow Up:</u> Admins have an option to initiate an email conversation with people from the dashboard itself</li>
                                        </ol>
                                    </li>
                                    <br />
                                    <li><b>Cohort Management:</b> Cohort is a method where the client can ask different sets of questions, to different audiences based on employee grade, business units and so on. Customer can add delete cohorts as they see fit. </li>
                                    <br />
                                    <li>
                                        <b>Access Management:</b> Access to the analytics can be of two types here: Full Access and Restricted Access.
                                        <ol type="i">
                                            <li><u>Full Access:</u> A user with this access has complete control of the Software and is called super admin and shall have access to all features subscribed to by the Customer.</li>
                                            <li><u>Restricted Access:</u> The customer can restrict access to the dashboard in the following ways:
                                                <ol>
                                                    <li>Restricted demographic access: In this access, admins can be created by giving them access to restricted demographics like department, location or custom user cohorts. In this access, they can have access to all features of the software.</li>
                                                    <li>Restricted feature access: In this access, admins can be created by giving full demographic access but certain sensitive features can be restricted</li>
                                                    <li>Restricted demographic and feature access: In this access, admins can be created by restricting both demographics and features on the software.</li>
                                                    <li>Hierarchy Access: Company can decide to provide access to business leaders or even managers basis their hierarchy. Further to their hierarchy, for these accesses, some features can be toggled off considering the sensitivity of data. </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <br />
                                    <li><b>Language Management:</b> Customers can request for languages to be turned on and off to better serve their employee base. </li>
                                    <br />
                                    <li><b>Keyword Alerts:</b> Customer can set up keyword alerts, to quickly alert them of specific keywords. These alerts will be dependent on the access control that is setup. </li>
                                    <br />
                                    <li><b>Employee Management:</b> Customers can add/update/delete employees that should be included under the Amber licenses mentioned in Clause A1. An alternative integration route is also available for this feature in which case this will not be available on the settings page. </li>
                                    <br />
                                    <li><b>AI Assist: </b>
                                        <ol type="i">
                                            <li>Upload Articles: Add policies, FAQs etc in the org that employees often ask for and keep them updated</li>
                                            <li>Create Workflows: Design the bot’s conversation, messages basis their org’s needs to give employees a quick turnaround time for their queries</li>
                                            <li>Access: Choose who can do the 2 steps mentioned above, view reporting and respond to questions which were unanswered by the bot</li>
                                        </ol>
                                    </li>
                                    <br />
                                </ol>
                            </li>
                            <li>The Company on a best effort basis shall ensure that the Software’s up time will be 99.95%. The Company shall also provide 24*5 tech support globally through its technical help desk – Which can be reached via email in <a href="mailto:help@infeedo.com">help@infeedo.com</a>.</li>
                        </ol>
                    </li>
                </ol>
                <br />
                <div className="column is-12 has-text-centered has-text-weight-bold">***</div>
                <h4 class="text-block-17 column is-12 is-paddingless has-text-centered mg-top-20"><u>Part B: Ancillary Services</u></h4>
                <h6><b>A. Corrective Maintenance: </b></h6>
                <ol type="i">
                    <li>The Company on a best effort basis shall ensure that the Software’s uptime will be 99.95%.</li>
                    <li>Maintenance of the Software shall comprise diagnosis and correction by the Company of actual errors or defects in the program codes of the components of the Software and/ or the provision of patches, bug fixes and workarounds (“Corrective Maintenance”) to errors reported by the Customer Company, which may/may not affect the Software’s uptime (as per the tables below). The tables set out below reflects Company’s response and resolution times.</li>
                    <li>The Customer shall report errors and defects to, the Customer Satisfaction Team of the Company via emailing the same to <a href="mailto:help@infeedo.com">help@infeedo.com</a>.</li>
                    <li>Company shall provide Corrective Maintenance in accordance with the timescales and priorities set out in the tables below. Response and resolutions times are measured from the time the Customer reports the error or defect to Company.</li>
                </ol>
                <br/>
                <h6><b>Table I: Priority Class, Response Time, Resolution Time, Service Credits</b></h6>
                <table className="table is-bordered is-fullwidth">
                    <tr>
                        <td>Priority</td>
                        <td className="width-170">Response Time</td>
                        <td className="width-240">Patch Fix</td>
                        <td>Resolution Time</td>
                    </tr>
                    <tr>
                        <td>Critical</td>
                        <td>1 Business hour</td>
                        <td>Within 8 hours</td>
                        <td>Resolution time shall be mutually accepted by the Parties after the effort assessment by the Company’s Engineering team.</td>
                    </tr>
                    <tr>
                        <td>High</td>
                        <td>4 Business hours</td>
                        <td>Within 24 Business hours</td>
                        <td>Resolution time shall be mutually accepted by the Parties after the effort assessment by the Company’s Engineering team.</td>
                    </tr>
                    <tr>
                        <td>Medium</td>
                        <td>24 Business hours</td>
                        <td>NA</td>
                        <td>7 Days</td>
                    </tr>
                </table>
                <h6 className="has-text-weight-normal"><i>Patch Fix: A temporary solution provided by the Company in order for the Customer to continue the use of the Software till the actual resolution time is determined by the Parties as per Table I above.</i></h6>
                <br />
                <h6><b>Table II: Priority Class Description/Classification</b></h6>
                <table className="table is-bordered">
                    <tr>
                        <td className="width-170">Priority Class</td>
                        <td>Description</td>
                    </tr>
                    <tr>
                        <td>Critical</td>
                        <td>Software has stopped responding or is so severely impacted that use of the Platform cannot continue. The error has one or more of the following characteristics:
                            <ol>
                                <li>Data corrupted</li>
                                <li>A critical documented function is not available</li>
                                <li>System hangs indefinitely, causing unacceptable or indefinite delays for resources or response. Subject to the following cases but not limited to – a) Chat reach outs not happening or are incorrect or chat link is inaccessible; b) Dashboard is not opening or is hung.</li>
                            </ol>
                            These cases are only applicable where causality is in the Company’s control.
                        </td>
                    </tr>
                    <tr>
                        <td>High</td>
                        <td>Partial loss of service. High-impact problem where the Platform is usable, but in a significantly impaired fashion; there is a time-sensitive issue important to long term use of the Software that is causing significant customer concern.
                            <br />
                            Important functions are unavailable with no workaround acceptable to the Buyer; however, operation of the Software by a user can continue on a restricted basis.
                            Example – a) Export feature has shut down; or b) a particular page is not accessible on the dashboard; or c) non-deliveries of automated reports.
                        </td>
                    </tr>
                    <tr>
                        <td>Medium</td>
                        <td>Minor loss of service. The impact is an inconvenience, which may require a workaround to restore that particular functionality.
                            <br />
                            Operation of the Software by a user is not adversely affected.
                            <br />
                            Examples include – the inability of the Customer to export a particular column from the dashboard.
                        </td>
                    </tr>
                    <tr>
                        <td>Low</td>
                        <td>A minor issue causing an inconvenience. There is no loss of service. The impact does not impede the operation of Software
                            Examples include: minor spelling errors.
                        </td>
                    </tr>
                </table>
                <h6 className="has-text-weight-normal"><i>All examples stated hereinabove are just indicative and not exhaustive</i></h6>
                <br />

                <ol type="a">
                    <li>In the event, despite a patch fix any critical/high priority Corrective Maintenance makes the Software unavailable for use for a period of 24 hours, the same shall be added in the term of the Agreement as a Service Credit, which shall be credited to the account of the Customer, whereby at the end of the term of the Agreement, the said term shall be increased without any additional cost by the number of Service Credits, credited to the account of the Customer. Each 24-hour period shall be equal to 1 (one) service credit. And 1 (one) service credit shall be equal to 1 (one) day’s extension to the Term of the Agreement. No service credits shall be provided for Medium/Low Level SLA’s/Corrective Maintenances.</li>
                    <li>Company warrants that it will perform all Corrective Maintenance using suitably qualified personnel, experienced with use of the Software and provision of the Services, using all due care and skill in conformity with Good Professional Practice.</li>
                    <li>AI Assist: Company shall provide the facility of AI Assist between: 10.00 am to 7.00 pm, India Local Time on all working/Business Days on a 24/5/365 basis.</li>
                </ol>
            </div>
        </div>
        <AmberScore />
    </Layout>
)

export default UnifiedSow